import React from 'react';
import profilePhoto from './images/profilePhoto.png';
import currentResume from "./resume/currentResume.pdf";
import qrcode from "./images/qrcode.svg";
import ReactCardFlip from "react-card-flip";
import {IoLogoLinkedin, IoLogoGithub} from 'react-icons/io5';
import {SiGmail} from 'react-icons/si';
import {GrDocumentText} from 'react-icons/gr';
import {TiArrowLoop} from 'react-icons/ti';

function App() {

  const [isFlipped, setIsFlipped] = React.useState(false);

  return(
    <div className="flex items-center justify-center md:bg-gradient-to-bl md:from-black md:to-blue-400">
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card className="CardFront" setIsFlipped={setIsFlipped} cardContent={<CardFront className="CardFront" name="Ben Elliott" email="elliottmben" imagePath={profilePhoto}/>} />
        <Card className="CardBack" setIsFlipped={setIsFlipped} cardContent={<CardBack />} />
      </ReactCardFlip>
    </div>
  );
}

function Card({cardContent, setIsFlipped}) {

  return (
    <div id="card" className="p-2 rounded-xl shadow-2xl font-sans h-full">
      {cardContent}
      <div onClick={() => {setIsFlipped((prev) => !prev)}} className="absolute bottom-0 right-0 cursor-pointer hover:opacity-30">
        <TiArrowLoop className="h-8 w-8 md:h-12 md:w-12" />
      </div>
    </div>
  );
}

function CardBack() {

  return (
    <div className="flex items-center justify-center h-full md:p-10">
      <div>
        <div className='flex items-center justify-center'>
          <img src={qrcode} alt="QR code" className="h-44 md:h-64" />
        </div>
        <div className='text-xl md:text-4xl text-center'>
          scan me 🙂
        </div>
      </div>
    </div>
  );
}

function CardFront({name, email, imagePath}) {

  return (
          <div className="flex justify-around items-center divide-x-[1px] divide-black h-full">
            <div className="h-full md:p-10 flex items-center justify-center">
              <img src={imagePath} alt="me" className='h-32 md:h-80'/>
            </div>
            <div className="h-full flex items-center justify-center p-2 md:p-6 ">
              <div className='divide-y-[1px] divide-black'>
                <div className="text-2xl  pb-1 md:text-6xl md:pb-4">
                  {name}
                </div>
                <div className="pt-1 md:pt-2">
                  <ContactElem icon={<SiGmail className='md:h-8 md:w-8' />} text={email} link="mailto:elliottmben@gmail.com"/>
                  <ContactElem icon={<IoLogoLinkedin className='md:h-8 md:w-8' />} text="benelliottcompsci" link="https://www.linkedin.com/in/benelliottcompsci/"/>
                  <ContactElem icon={<IoLogoGithub className='md:h-8 md:w-8' />} text="elliottben" link="https://github.com/elliottben" />
                  <ContactElem icon={<GrDocumentText className='md:h-8 md:w-8' />} text="resume" link={currentResume}/>
                </div>
              </div>
            </div>
          </div>
  );
}

function ContactElem({icon, text, link}) {

  return (
    <a className="p-1 md:p-2 flex justify-start hover:opacity-30 cursor-pointer" href={link} target="_blank" rel="noopener noreferrer">
      <div className="pr-2 pb-1 pt-1 pl-1 md:pr-3 flex items-end justify-center">
        {icon}
      </div>
      <div className="text-xl md:p-1 md:text-4xl flex items-center">
          {text}
      </div>
    </a>
  )
}



export default App;